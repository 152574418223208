import { IMenu } from '../models/menu.model';
import { ACCESS_ROLES } from './access-role.constant';

export const MENUS: IMenu[] = [
    {
        name: 'Program Tour List',
        link: '/product/program-tour-list',
        isHidden: false,
        accessRoles: ACCESS_ROLES.PROGRAM_TOUR_LIST_PAGE,
        slug: 'program-tour-list',
    },
    {
        name: 'Leads List',
        link: '/booking/list-v2',
        isHidden: false,
        accessRoles: ACCESS_ROLES.ALL,
        slug: 'booking-list-v2',
    },
    {
        name: 'Order List',
        link: '/order/list',
        isHidden: false,
        accessRoles: ACCESS_ROLES.ORDER_CREATE_PAGE,
        slug: 'order-list',
    },
    {
        name: 'Work List',
        link: '/sale-support/work-list',
        isHidden: false,
        accessRoles: ACCESS_ROLES.WORKLIST_GROUP,
        slug: 'work-list',
    },
    {
        name: 'Customer Relation List',
        link: '/customers/list',
        isHidden: false,
        accessRoles: ACCESS_ROLES.ORDER_CREATE_PAGE,
        slug: 'customer-relation-list',
    },
    {
        name: 'Commission Report (Old Customer)',
        link: '/commission-report/old-customer',
        isHidden: false,
        slug: 'commission-report-old-customer',
    },
    {
        name: 'Commission Report (New Customer)',
        link: '/commission-report/new-customer',
        isHidden: false,
        slug: 'commission-report-new-customer',
    },
    {
        name: 'Commission Report',
        link: '/commission-report/list',
        isHidden: false,
        slug: 'commission-report',
    },
    {
        name: 'Telesales Report',
        link: '/report/telesales-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.TELESALES_REPORT,
        slug: 'telesales-report',
    },
    {
        name: 'Cash Outflow Report',
        link: '/cash-outflow-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.CASH_OUTFLOW_REPORT_LIST_PAGE,
        slug: 'cash-outflow-report',
    },
    {
        name: 'Cash Inflow Report',
        link: '/report/cash-inflow-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.CASH_INFLOW_REPORT,
        slug: 'cash-inflow-report',
    },
    {
        name: 'Finance Management',
        link: null,
        isHidden: false,
        accessRoles: ACCESS_ROLES.FINANCE_MANAGEMENT_GROUP,
        slug: 'finance-management',
        subMenu: [
            {
                name: 'Order List',
                link: '/order-balance/order-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ORDER_LIST_PAGE,
            },
            {
                name: 'ข้อมูลการจองทัวร์ (TW)',
                link: '/tw-booking/list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.TW_ORDER_LIST_PAGE,
            },
            {
                name: 'Order Matching',
                link: '/order-balance/tw-order-matching-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.TW_ORDER_MATCHING_LIST_PAGE,
            },
            {
                name: 'ข้อมูลการซื้อโปรแกรมทัวร์',
                link: '/order-balance/order-program-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ORDER_PROGRAM_LIST_PAGE,
            },
            {
                name: 'Order Balancing List',
                link: '/order-balance/order-balance-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ORDER_BALANCE_LIST_PAGE,
            },
            {
                name: 'Agency Commission',
                link: '/order-balance/agency-commission',
                isHidden: false,
                accessRoles: ACCESS_ROLES.AGENCY_COMMISSION_PAGE,
            },
            {
                name: 'Order Voucher List',
                link: '/order-balance/voucher-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ORDER_VOUCHER_LIST_PAGE,
            },
            {
                name: 'Seller Voucher',
                link: '/order-balance/seller-voucher',
                isHidden: false,
                accessRoles: ACCESS_ROLES.SELLER_VOUCHER_PAGE,
            },
            {
                name: 'Fee List',
                link: '/order-balance/fee-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.FEE_LIST_PAGE,
            },
            {
                name: 'Tourwow Fee',
                link: '/order-balance/tourwow-fee',
                isHidden: false,
                accessRoles: ACCESS_ROLES.TOURWOW_FEE_PAGE,
            },
        ],
    },
    {
        name: 'Product Management',
        link: null,
        isHidden: false,
        accessRoles: ACCESS_ROLES.PRODUCT_MANAGEMENT_GROUP,
        slug: 'product-management',
        subMenu: [
            {
                name: 'Keyword List',
                link: '/product/keyword-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.KEYWORD_LIST_PAGE,
            },
            {
                name: 'Period Management',
                link: '/product/period-management-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.PERIOD_MANAGEMENT_LIST_PAGE,
            },
        ],
    },
    {
        name: 'R&D For IT',
        link: null,
        isHidden: false,
        accessRoles: ACCESS_ROLES.RD_FOR_IT,
        slug: 'research-and-development-for-id',
        subMenu: [
            {
                name: 'Booking List',
                link: '/booking/list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ALL,
                slug: 'booking-list',
            },
            {
                name: 'Order List V2',
                link: '/order/list-v2',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ORDER_CREATE_PAGE,
                slug: 'order-list-v2',
            },
            {
                name: 'Sitemap List',
                link: '/sitemap/sitemap-list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ALL,
                slug: 'sitemap-list',
            },
            {
                name: 'Sales Funnel Report',
                link: '/report/sales-funnel',
                isHidden: false,
                slug: 'sales-funnel-report',
            },
            {
                name: 'Order Management',
                link: '/order/management',
                isHidden: false,
                slug: 'order-management',
            },
        ],
    },
    {
        name: 'Page Config',
        link: null,
        isHidden: false,
        accessRoles: ACCESS_ROLES.PAGE_CONFIG,
        slug: 'page-config',
        subMenu: [
            {
                name: 'Promotion List',
                link: '/config/promotion/list',
                isHidden: false,
                accessRoles: ACCESS_ROLES.ALL,
                slug: 'promotion-list',
            },
        ],
    },
    {
        name: 'Count Leads Report',
        link: '/report/count-leads-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.COUNT_LEADS_REPORT,
        slug: 'count-leads',
    },
    {
        name: 'Orders Count Report',
        link: '/report/orders-count-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.ORDERS_COUNT_REPORT,
        slug: 'orders-count',
    },
    {
        name: 'Orders Report for Wholesale Payment',
        link: '/report/orders-report-for-wholesale-payment',
        isHidden: false,
        accessRoles: ACCESS_ROLES.ORDER_REPORT_FOR_WHOLESALE_PAYMENT,
        slug: 'orders-report-for-wholesale-payment',
    },
    {
        name: 'Daily Win Report',
        link: '/report/daily-win-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.ALL,
        slug: 'daily-win-report',
    },
    {
        name: 'Tracking Leads Report',
        link: '/report/tracking-leads-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.TRACKING_LEADS_REPORT,
        slug: 'tracking-leads-report',
    },
    {
        name: 'Survey Report',
        link: '/report/survey-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.SURVEY_REPORT,
        slug: 'survey-report',
    },
    {
        name: 'OTP Login Report',
        link: '/report/otp-login-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.OTP_LOGIN_REPORT,
        slug: 'otp-login-report',
    },

    {
        name: 'Pipeline Management',
        link: null,
        isHidden: false,
        accessRoles: ACCESS_ROLES.PIPELINE_MANAGEMENT_GROUP,
        slug: 'pipeline-management',
        subMenu: [
            {
                name: 'Create Pipeline',
                link: '/pipeline/create',
                isHidden: false,
            },
            {
                name: 'Pipeline List',
                link: '/pipeline/list',
                isHidden: false,
            },
        ],
    },
    {
        name: 'Crm Kpi Report',
        link: '/report/crm-kpi-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.CRM_KPI_REPORT,
        slug: 'crm-kpi-report',
    },
    {
        name: 'Tracking Report',
        link: '/report/tracking-report',
        isHidden: false,
        accessRoles: ACCESS_ROLES.TRACKING_REPORT,
        slug: 'tracking-eport',
    },
    {
        name: 'Logout',
        link: '/logout',
        isHidden: false,
        accessRoles: ACCESS_ROLES.ALL,
        slug: 'logut',
    },
];
