import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ENDPOINT } from 'src/app/constants/endpoint.constant';
import { IApiResponse } from 'src/app/models/interfaces/api-response.interface';
import { HttpService } from 'src/app/services/base/http.service';
import { environment } from 'src/environments/environment';
import { IBookingPaginatedFilter } from '../interfaces/booking-paginated-filter.interface';
import { IPriorityStatus } from '../interfaces/booking-priority-status.interface';
import { IBookingTeamNumber } from '../interfaces/booking-team-number.interface';
import { IBooking, ICountry, IMember } from '../interfaces/booking.interface';
import { IPaginatedResponse, IPaginatedResponseV2 } from '../interfaces/paginated-response.interface';
import { ITrackingReportFilter } from '../interfaces/sell-tracking-report-filter.interface';
import { IBookingCountReportFilter } from '../interfaces/booking-count-report-filter.interface';
import { ITelesalesReportFilters } from '../../report/pages/telesales-report/interfaces/telesales-report-filters.interface';
import { IAgencyMember } from 'src/app/models/tw-booking-model/tw-booking-interface/agency-member.interface';
import { ILeadTrackingReportFilter } from '../interfaces/lead-tracking-report-filter.interface';
import { IBookingUpdatedRequest } from '../interfaces/booking-update.interface';
import { IBookingFilter } from '../interfaces/booking-filter.interface';
import { BookingResult } from '../interfaces/booking-result.interface';
import {
    IBookingTrackingMember,
    IBookingTrackingMemberError,
    IBookingTrackingMemberSuccess,
} from '../interfaces/booking-tracking-member-update.interface';
import { IBookingByCode } from '../interfaces/booking-by-code.interface';

@Injectable({
    providedIn: 'root',
})
export class BookingService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    getBookingPaginated(
        filters?: IBookingPaginatedFilter,
        page?: number,
        pageEntries?: number
    ): Observable<IPaginatedResponse<IBooking[]>> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_BOOKING_PAGINATED;
        let params = new HttpParams();

        params = params.append('filters', JSON.stringify(filters));
        params = params.append('page', JSON.stringify(page));
        params = params.append('page_entries', JSON.stringify(pageEntries));

        return this.get(url + '?' + params.toString(), true).pipe(
            map((resp: IApiResponse<IPaginatedResponse<IBooking[]>>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getBooking(bookingCode: string): Observable<IBookingByCode> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_BOOKING;
        url = url.replace('{booking_code}', bookingCode);
        return this.get(url, true).pipe(
            map((resp: IApiResponse<IBookingByCode>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getAllBookings(filter: IBookingFilter, sortBy: string): Observable<BookingResult[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_ALL_BOOKING;
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filter));
        params = params.append('sort_by', sortBy);

        return this.get(url + '?' + params.toString(), true).pipe(
            map((resp: IApiResponse<BookingResult[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getBookingTeamNumbers(): Observable<IBookingTeamNumber[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_BOOKING_TEAM_NUMBERS;
        return this.get(url, true).pipe(
            map((resp: IApiResponse<IBookingTeamNumber[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getMembersCreateBooking(): Observable<IMember[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_MEMBERS_CREATE_BOOKING;
        return this.get(url, true).pipe(
            map((resp: IApiResponse<IMember[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getMembersTrackingBooking(teamNumber: number | null, isActive: boolean = true): Observable<IMember[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_MEMBERS_TRACKING_BOOKING;
        let params = new HttpParams();
        params = params.append(
            'filters',
            JSON.stringify({
                team_number: teamNumber ? teamNumber : undefined,
                is_active: isActive,
            })
        );

        return this.get(url + '?' + params.toString(), true).pipe(
            map((resp: IApiResponse<IMember[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    updateMembersTrackingBooking(
        id: number,
        body: IBookingTrackingMember,
        prefix: string = ''
    ): Observable<IApiResponse<IBookingTrackingMemberSuccess> | IApiResponse<IBookingTrackingMemberError>> {
        let url = environment.apiUrl + prefix + ENDPOINT.BOOKING.UPDATE_MEMBERS_TRACKING_BOOKING;
        url = url.replace('{id}', id.toString());

        const token: string | null = this.getToken();

        const options = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token,
            }),
        };

        return this.http.put<IApiResponse<IBookingTrackingMemberSuccess>>(url, body, options).pipe(
            map((resp: IApiResponse<IBookingTrackingMemberSuccess>) => {
                return resp;
            }),
            catchError((error) => {
                return of(error.error);
            })
        );
    }

    getMemberTrackingSellerTeam(): Observable<IBookingTeamNumber[]> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_MEMBERS_TRACKING_SELLER_TEAMS}`;

        return this.get(url, true).pipe(
            map((resp: IApiResponse<{ team_number: number }[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getCustomerPriorityStatus(): Observable<IPriorityStatus[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_CUSTOMER_PRIORITY_STATUS;
        return this.get(url, true).pipe(
            map((resp: IApiResponse<IPriorityStatus[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getCreatedAgencyTeam(): Observable<IBookingTeamNumber[]> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_CREATED_AGENCY_MEMBER_TEAMS}`;

        return this.get(url, true).pipe(
            map((resp: IApiResponse<{ team_number: number }[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getCreateByChannels(): Observable<IBookingCreateByChannelResponse[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_CREATE_BY_BOOKING_CHANNELS;
        return this.get(url, true).pipe(
            map((resp: IApiResponse<IBookingCreateByChannelResponse[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getBookingCountries(): Observable<ICountry[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_BOOKING_COUNTRIES;
        return this.get(url, true).pipe(
            map((resp: IApiResponse<ICountry[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getBookingPriorityStatus(): Observable<IPriorityStatus[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_PRIORITY_STATUS;
        return this.get(url, true).pipe(
            map((resp: IApiResponse<IPriorityStatus[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getBookingChannel(): Observable<IBookingChannelResponse[]> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_BOOKING_CHANNELS;
        return this.get(url, true).pipe(
            map((resp: IApiResponse<IBookingChannelResponse[]>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getHasMatchingLastView(bookingCode: string, memberId: number | null): Observable<boolean> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.PUT_HAS_MATCHING_LAST_VIEW;

        url = url.replace('{booking_code}', bookingCode.toString());

        return this.put(url, { last_viewed_by_agency_member_id: memberId }, true).pipe(
            map((resp: IApiResponse<IHasMatchingLastViewResponse>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data.has_matching_last_view;
            })
        );
    }

    updateHasMatchingLastView(bookingCode: string, memberId: number | null): Observable<IUpdateLastViewResponse> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.UPDATE_LAST_VIEW;

        url = url.replace('{booking_code}', bookingCode.toString());

        return this.put(url, { last_viewed_by_agency_member_id: memberId }, true).pipe(
            map((resp: IApiResponse<IUpdateLastViewResponse>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    // Queue
    queueCheckIn(): Observable<IApiResponse<any>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.QUEUE_CHECKIN}`;
        return this.post(url, {});
    }

    queueCheckOut(): Observable<IApiResponse<any>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.QUEUE_CHECKOUT}`;
        return this.post(url, {});
    }

    queueRevoke(): Observable<IApiResponse<any>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.QUEUE_REVOKE}`;
        return this.post(url, {});
    }

    getActivatedQueue(team: number | null = null): Observable<IApiResponse<IAcitiveAgencyMember[]>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.QUEUE_GET_ACTIVATED_MEMBER}`;
        let params = new HttpParams();
        if (team) {
            params = params.append(
                'filters',
                JSON.stringify({
                    team: team,
                })
            );
        }

        return this.get(url + '?' + params.toString(), true);
    }

    checkQueueStatus(): Observable<IApiResponse<ICheckStatusResponse>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.QUEUE_CHECK_STATUS}`;
        return this.get(url);
    }

    // Task Tracking
    getTaskTracking(filters: ITrackingReportFilter = {}): Observable<IApiResponse<ITaskTrackingReport[]>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_TASK_TRACKING_REPORT}`;
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filters));

        return this.get(url + '?' + params.toString(), true);
    }

    getSellTracking(filters: ITrackingReportFilter = {}): Observable<IApiResponse<any>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_SELL_TRACKING_REPORT}`;
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filters));

        return this.get(url + '?' + params.toString(), true);
    }

    getTeleSaleTracking(): Observable<IApiResponse<{ id: number; nick_name: string }[]>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_TELESALE_TRACKING}`;
        return this.get(url, true);
    }

    //
    getBookingCountReport(
        filters?: IBookingCountReportFilter,
        page: number = 1,
        pageEntries: number = 20
    ): Observable<IApiResponse<IBookingCountReportResponsePaginated>> {
        let params = new HttpParams();
        if (filters) {
            params = params.append('filters', JSON.stringify(filters));
        }

        params = params.append('page', page);
        params = params.append('page_entries', pageEntries);

        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_BOOKING_COUNT_REPORT}`;
        return this.get(url + '?' + params.toString(), true);
    }

    getBookingTelesalesPerformanceReport(
        filters?: ITelesalesReportFilters,
        page: number = 1,
        pageEntries: number = 20
    ): Observable<IPaginatedResponse<IBooking[]>> {
        let params = new HttpParams();
        if (filters) {
            params = params.append('filters', JSON.stringify(filters));
        }

        params = params.append('page', page);
        params = params.append('page_entries', pageEntries);

        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_BOOKING_TELESALES_PERFORMANCE_REPORT}`;
        return this.get(url + '?' + params.toString(), true).pipe(
            map((resp: IApiResponse<IPaginatedResponse<IBooking[]>>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    // Task Tracking V2
    getBookingConfigs(): Observable<IApiResponse<IBookingConfig[]>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_BOOKING_CONFIGS}`;
        return this.get(url, true);
    }

    getTaskTrackingV2(filters: ILeadTrackingReportFilter = {}): Observable<IApiResponse<ITaskTrackingV2Response[]>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_TASK_TRACKING_REPORT_V2}`;
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filters));

        return this.get(url + '?' + params.toString(), true);
    }

    getSellTrackingV2(filters: ILeadTrackingReportFilter = {}): Observable<IApiResponse<ISellTrackingV2Response[]>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_SELL_TRACKING_REPORT_V2}`;
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filters));

        return this.get(url + '?' + params.toString(), true);
    }

    getBookingPaginatedV2(
        filters?: IBookingPaginatedFilter,
        page?: number,
        pageEntries?: number
    ): Observable<IPaginatedResponseV2<IBooking[]>> {
        let url = environment.apiUrl + ENDPOINT.BOOKING.GET_BOOKING_PAGINATED_V2;
        let params = new HttpParams();

        params = params.append('filters', JSON.stringify(filters));
        params = params.append('page', JSON.stringify(page));
        params = params.append('page_entries', JSON.stringify(pageEntries));

        return this.get(url + '?' + params.toString(), true).pipe(
            map((resp: IApiResponse<IPaginatedResponseV2<IBooking[]>>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp.data;
            })
        );
    }

    getSummaryBookingReport(filters: ISummaryBookingReportFilter): Observable<IApiResponse<ISummaryBookingReport>> {
        let url = `${environment.apiUrl}${ENDPOINT.BOOKING.GET_SUMMARY_BOOKING_REPORT}`;
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filters));
        return this.get(url + '?' + params.toString(), true);
    }

    updateBooking(
        bookingCode: string,
        payload: IBookingUpdatedRequest
    ): Observable<IApiResponse<{ booking_id: number }>> {
        let url = `${environment.apiUrl}v2/${ENDPOINT.BOOKING.GET_BOOKING}`.replace('{booking_code}', bookingCode);
        return this.put(url, payload, true);
    }

    deleteBooking(bookingCode: string): Observable<IApiResponse<{ booking_id: number }>> {
        let url = `${environment.apiUrl}v2/${ENDPOINT.BOOKING.GET_BOOKING}`.replace('{booking_code}', bookingCode);
        return this.delete(url, true);
    }
}

export interface IBookingConfig {
    id: number;
    expiry_in_days: number;
    type: string;
}

export interface ISummaryBookingReport {
    doesnt_have_seller_agency_member_and_created_by_agency_member_count: number;
    doesnt_have_seller_agency_member_but_created_by_agency_member_count: number;
    waiting_confirmation_order_status_count: number;
    waiting_first_payment_order_status_count: number;
}

export interface ISummaryBookingReportFilter {
    seller_agency_member_team_number?: number | null;
    created_agency_member_team_number?: number | null;
    seller_agency_member_id?: number | null;
    exclude_order_status: string;
}

export interface ITaskTrackingV2Response {
    follow_hot_tracking_priority_status: number;
    follow_tracking_priority_status: number;
    hot_tracking_priority_status: number;
    seller_by_agency_member: {
        id: number;
        nick_name: string;
        team_number: number;
    };
}

export interface ISellTrackingV2Response {
    follow_hot_tracking_priority_status: number;
    follow_tracking_priority_status: number;
    hot_tracking_priority_status: number;
    order_count: number;
    team_number: number;
}

export interface IBookingCountReportResponsePaginated {
    page: number;
    page_entries: number;
    total: number;
    total_lead_counts: {
        created_by_agency_members: {
            agency_member: IAgencyMember;
            first_customer_chat_url_line_created_at_count: number;
        }[];
    };
    total_booking_counts: {
        backoffice_channel: number;
        created_by_agency_members: {
            agency_member: IAgencyMember;
            booking_channel_count: number;
        }[];
        tourwow_channel: number;
    };
    total_order_counts: {
        backoffice_channel: number;
        created_by_agency_members: {
            agency_member: IAgencyMember;
            backoffice_channel_count: number;
        }[];
        tourwow_channel: number;
    };
    result: IBookingCountReportResponse[];
}

export interface IBookingCountReportResponse {
    created_at_date: string;
    created_by_agency_members: {
        agency_member: {
            id: number;
            nick_name: string;
        };
        booking_channel_counts: {
            facebook_type: number;
            line_oa_type: number;
            website_type: number;
        };
    }[];
    created_by_agency_members_with_lead: {
        agency_member: {
            id: number;
            nick_name: string;
        };
        first_customer_chat_url_line_created_at_count: number;
    }[];
    created_by_agency_members_with_order: {
        agency_member: {
            id: number;
            nick_name: string;
        };
        seller_agency_members: {
            agency_member: {
                id: number;
                nick_name: string;
            };
            backoffice_channel_count: number;
        }[];
        backoffice_channel_count: number;
    }[];
    customer: {
        booking_channel_counts: {
            facebook_type: number;
            line_oa_type: number;
            website_type: number;
        };
    };
    total_booking_counts: {
        backoffice_channel: number;
        tourwow_channel: number;
    };
    total_order_counts: {
        backoffice_channel: number;
        tourwow_channel: number;
    };
}

export interface IBookingCreateByChannelResponse {
    created_by_channel: string;
}
export interface IBookingChannelResponse {
    id: number;
    name: string;
    team_number: number;
}

export interface IHasMatchingLastViewResponse {
    has_matching_last_view: true;
}

export interface IUpdateLastViewResponse {
    booking_id: number;
    booking_code: string;
}

export interface ICheckStatusResponse {
    status: number; // -1 is not in booking queue, 0 is not inactive and 1 is activated.
    message: string;
}

export interface IAcitiveAgencyMember {
    agency_member_id: number;
    checkin_at: string;
    first_name: string | null;
    last_name: string | null;
    nick_name: string | null;
    team_number: number;
}

export interface ITaskTrackingReport {
    seller_by_agency_member: {
        id: number;
        nick_name: string;
        team_number: number;
    };
    booking_without_tracking_count: number;
    new_booking_without_tracking_count: number;
    follow_booking_with_active_follow_tracking_count: number;
    reject_booking_with_active_reject_tracking_count: number;
    total_count: number;
}

export interface ISellTracking {
    seller_by_agency_member: {
        id: number;
        nick_name: string;
        team_number: number;
    };
    success_booking_with_active_follow_tracking_count: number;
}
